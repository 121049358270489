<template>
  <div class="home">
    <div v-if="loggedin">
      <div class="mobile">Mobile</div>
      <div class="desktop">Desktop</div>
    </div>
    <div v-else>
      <Hero @auth="isLogged" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Hero from "@/components/Hero.vue";

export default {
  name: "Home",
  components: {
    Hero,
  },
  data() {
    return {
      loggedin: false,
    };
  },
  mounted() {
    //this.loadData();
  },
  methods: {
    loadData() {
      axios
        .get("https://islingtongroup.co.nz/_api/b2b/dispatch.php")
        .then(({ data }) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          this.loadData();
        });
    },
    isLogged(e) {
      if (e) {
        this.loggedin = true;
        this.loadData();
      } else {
        this.loggedin = false;
      }
    }
  },
};
</script>

<style scoped>
.mobile {
  display: none;
}
.login {
  max-width: 400px;
  text-align: left;
}
.callToAction {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
