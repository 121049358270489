<template>
  <div>
    Inform the One Time Password (OTP) <br />
    sent by SMS to
    <span style="font-weight: bold">
      {{ phone.replace("2", "*") }}
    </span>
    <!-- {{ user.Phone }}: -->
    <form id="formotp">
      <div style="margin: 10px; width: 300px">
        <input
          id="dig1"
          type="text"
          maxlength="1"
          class="otp"
          v-model="dig1"
          @keyup="setfocus()"
        />
        <input
          id="dig2"
          type="text"
          maxlength="1"
          class="otp"
          v-model="dig2"
          @keyup="setfocus()"
        />
        <input
          id="dig3"
          type="text"
          maxlength="1"
          class="otp"
          v-model="dig3"
          @keyup="setfocus()"
        />
        <input
          id="dig4"
          type="text"
          maxlength="1"
          class="otp"
          v-model="dig4"
          @keyup="setfocus()"
        />
        <input
          id="dig5"
          type="text"
          maxlength="1"
          class="otp"
          v-model="dig5"
          @keyup="checkOpt()"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "OneTimePassword",
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dig1: "",
      dig2: "",
      dig3: "",
      dig4: "",
      dig5: "",
    };
  },
  mounted() {
    document.getElementById("dig1").focus();
  },
  methods: {
    setfocus() {
      console.log(isNaN(this.dig5));
      if (this.dig5 == "") {
        document.getElementById("dig5").focus();
      }
      if (this.dig4 == "") {
        document.getElementById("dig4").focus();
      }
      if (this.dig3 == "") {
        document.getElementById("dig3").focus();
      }
      if (this.dig2 == "") {
        document.getElementById("dig2").focus();
      }
      if (this.dig1 == "") {
        document.getElementById("dig1").focus();
      }
    },
    verifyDigit(i) {
      console.log(i);
    },
    checkOpt() {
      if (
        (this.dig1 != "",
        this.dig2 != "",
        this.dig3 != "",
        this.dig4 != "",
        this.dig5 != "")
      ) {
        console.log(this.dig1, this.dig2, this.dig3, this.dig4, this.dig5);
        this.$emit(
          "opt",
          [this.dig1, this.dig2, this.dig3, this.dig4, this.dig5].join("")
        );
      }
    },
  },
};
</script>

<style scoped>
.otp {
  width: 42px;
  text-align: center;
  font-size: 2.2em;
  padding-top: 5px;
  padding-bottom: 7px;
  border-radius: 7px;
  border: none;
  margin: 5px;
}
</style>
