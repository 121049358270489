<template>
  <div class="hero">
    <div class="hero-image">
      <div class="logo">
        <div>
          <img src="../assets/logo.svg" />
        </div>
        <div style="font-size: 0.7em; padding: 10px">WE MAKE FENCING EASY</div>
        <Divider />
      </div>
      <div class="hero-text">
        <div v-if="!showotp">
          <h1>Trade App</h1>
          <div v-if="!error" class="box">
            Follow up your orders in realtime
          </div>
          <div v-else style="max-width: 400px; min-width: 270px; margin-bottom: 10px">
            <span style="color: #e21e36;">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span style="font-weight: bold"> Email not authorised</span>
            <br />
            Verify if you have typed correctly or contact our customer service
            at 0800 003 006.
          </div>
          <form name="myform" @submit="submitForm">
            <div class="form-login">
              <div style="flex: 4">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter your email address"
                  v-model="email"
                  required
                  @keyup="countInput"
                />
              </div>
              <div style="flex: 1" class="action-button">
                <div style="margin-left: 5px">
                  <button
                    type="submit"
                    class="btn btn-danger"
                    style="width: 100%"
                    v-if="!checking"
                  >
                    <i class="fa fa-sign-in" aria-hidden="true"></i>
                  </button>
                  <button
                    class="btn btn-danger"
                    style="width: 100%"
                    v-if="checking"
                    disabled
                  >
                    <Spinner />
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div style="font-size: 0.7em; margin-top: 10px">
            *This application is for exclusive use of Boundaryline's B2B
            customers
          </div>
        </div>
        <OneTimePassword
          v-if="showotp && user != ''"
          :phone="user.Phone"
          @opt="testOpt"
        />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Divider from "@/components/Hero/Divider.vue";
import OneTimePassword from "@/components/Hero/OTP.vue";
import Spinner from "@/components/Hero/Spinner.vue";
import Footer from "@/components/Hero/Footer.vue";

export default {
  name: "Hero",
  components: {
    Divider,
    Footer,
    OneTimePassword,
    Spinner,
  },
  data() {
    return {
      checking: false,
      disabled: true,
      email: "",
      error: false,
      showotp: false,
      user: "",
    };
  },
  mounted() {},
  methods: {
    submitForm(e) {
      this.error = false;
      this.showotp = false;
      this.checking = true;
      e.preventDefault();
      console.log("Login attempt");
      if (this.email.length > 5 && this.email.includes("@")) {
        axios
          .post("https://islingtongroup.co.nz/_api/b2b/login.php", {
            email: this.email,
          })
          .then(({ data }) => {
            console.log(data);
            if (data == "ERROR") {
              // If not an authenticated user
              this.error = true;
              this.checking = false;
            } else {
              this.user = data;
              this.showotp = true;
            }
          })
          .catch((err) => {
            this.submitForm();
            console.log(err);
          });
      }
    },
    countInput() {
      //console.log(this.email);
      if (this.email.length > 5 && this.email.includes("@")) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    testOpt(e) {
      console.log("OPT: ", e.toUpperCase(), this.user.OTP);
      if (e.toUpperCase() == this.user.OTP) {
        console.log("authorised");
        this.$emit("auth", true);
      } else {
        console.log("not authorised");
        this.$emit("auth", false);
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  position: absolute;
  top: 30px;
  width: 100%;
  text-align: center;
}

.logo img {
  width: 180px;
}

.hero {
  height: 100vh;
  color: #ffffff;
}
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.boundaryline.co.nz/wp-content/uploads/2021/05/119edited.jpg");

  /* Set a specific height */
  height: 100%;
  min-height: 600px;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-login {
  display: flex;
  padding-top: 5px;
}

.action-button {
  margin-left: 5px;
}

.mobile {
  visibility: visible;
}

.box {
  max-width: 400px;
  min-width: 400px;
  margin-bottom: 10px;
}

input {
  background-color: rgba(255, 255, 255, 0.75);
  border: solid 1px rgba(255, 255, 255, 0.4);
}

.disclaimer {
  font-size: 0.7em;
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 1024px) {
  .logo {
    width: 100%;
  }
  .action-button {
    margin-left: 0px;
  }
  .mobile {
    display: none;
  }
  .box {
    max-width: 250px;
    min-width: 250px;
  }
}
</style>
