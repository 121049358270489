import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "../node_modules/font-awesome/css/font-awesome.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";

Vue.config.productionTip = false;

// Day.js
Vue.prototype.dayjs = dayjs;
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
